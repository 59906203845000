export const noError = {
  isError: false,
}

export const initialState = {
  error: noError,
  isLoading: false,
  location: 'IN',
}


