// import EcomComponent from '../Views/Ecom'

import StaticSiteRoutes from '../Views/StaticSite/Constants/routes'

export const MainRoutes = [
  ...StaticSiteRoutes,
  // {
  //   Component: EcomComponent,
  //   path: '/ecom/*',
  //   exact: false,
  //   id: 'Ecom',
  //   routePath: '/ecom',
  //   name: 'ecom',
  // }
]
