// export const authServerClientId = 'prod-tyi-cms'
// export const cmsBaseDomain = 'https://cms-prod-be.theyogainstitute.org/v1'
// // export const cmsBaseDomain = 'https://39c8-203-132-133-126.ngrok-free.app/v1'
// export const authBaseDomain = 'https://www.authserver-prod-be.theyogainstitute.org/v2'
// export const ecomBaseDomain = 'https://https://ecom-prod-be.theyogainstitute.org/v1'


//
// export const authServerClientId = 'cmsProd_qt9up36idOpBAcrrd'
// export const cmsBaseDomain = 'https://tyi-uat-cms.dotcod.in/api/v1'
// export const authBaseDomain = 'https://tyi-uat-auth-api.dotcod.in/api/v2'
// export const ecomBaseDomain = 'https://tyi-uat-ecom.dotcod.in/api/v1'
// export const razorPayKey = 'rzp_test_hWMewRlYQKgJIk'

 export const authServerClientId = 'cmsProd_qt9up36idOpBAcrrd'
export const cmsBaseDomain = 'https://cms-prod-be.theyogainstitute.org/v1'
export const authBaseDomain = 'https://authserver-prod-be.theyogainstitute.org/v2'
export const ecomBaseDomain = 'https://ecom-prod-be.theyogainstitute.org/v1'
export const razorPayKey = 'rzp_live_KyhtrIyJ546bd2'


// export const cmsBaseDomain = 'https://tyi-test.theyogainstitute.org/cms-api/v1'
// export const authBaseDomain = 'https://tyi-test.theyogainstitute.org/auth-api/v2'
// export const ecomBaseDomain = 'https://tyi-test.theyogainstitute.org/ecom-api/v1'
// export const razorPayKey = 'rzp_test_hWMewRlYQKgJIk'
