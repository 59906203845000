export const noError = {
  isError : false
}

export const initialState = {
  error: noError,
  isLoading: false,
  count:0,
  blogs:[],
  blog:{}
}
